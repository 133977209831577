<template>
  <div class="lx">
    <!-- <div class="home-b">
      <div class="home-b-text">
        <div class="home-left">
          <p>业务咨询</p>
          <p>随时联系我们的咨询专家</p>
          <p>
            <span class="ht" style="position: relative; top: 3px"></span>
            <span>韩经理：13995648183</span>
          </p>
        </div>
        <div class="home-left-1">
          <p>商务邮箱</p>
          <p>我们的商务经理会第一时间联系您</p>
          <p>
            <img
              style="position: relative; top: -2px; right: 5px"
              src="../../../assets/images/Lx/z-1-4.png"
            />
            <span>zuoyi@iningmei.com</span>
          </p>
        </div>
        <div class="home-two">
          <div class="home-content">
            <p></p>
            <p>在线联系我们，获得专业建议</p>
            <p @click="handleOpen">立即咨询</p>
          </div>
          <div class="home-right">
            <p></p>
            <p>提交产品需求，保持沟通</p>
            <p @click="workOrderSubmite">立即提交</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="lx-content">
      <div class="content-fore">
        <div class="one-1">
          <div class="one-1-1"><img src="../images/j4.png" alt="" /></div>
          <div class="one-1-2">
            <span>业务咨询：</span><span>韩经理&nbsp;13995648183</span>
          </div>
          <div class="one-1-3">随时联系我们的咨询专家为您答疑解惑</div>
        </div>
        <div class="one-2">
          <div class="one-1-1"><img src="../images/j1.png" alt="" /></div>
          <div class="one-1-2">
            <span>商务邮箱：</span><span>zuoyi@iningmei.com</span>
          </div>
          <div class="one-1-3">我们的商务经理收到邮件会第一时间联系您</div>
        </div>
        <div class="one-3" @click="handleOpen">
          <div class="one-1-1"><img src="../images/j2.png" alt="" /></div>
          <div class="one-1-2">
            <span>在线咨询</span
            ><span><img src="../images/j5.png" alt="" /></span>
          </div>
          <div class="one-1-3">在线咨询技术问题，技术专家将为您即时解答</div>
        </div>
        <div class="one-4" @click="workOrderSubmite">
          <div class="one-1-1"><img src="../images/j3.png" alt="" /></div>
          <div class="one-1-2">
            <span>提交需求</span
            ><span><img src="../images/j5.png" alt="" /></span>
          </div>
          <div class="one-1-3">提交产品需求，我们将更精准的为您提供服务</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    style="border-radius: 20px; width: 521px; height: 646px; position: relative"
    :show-close="false"
    destroy-on-close="true"
    v-model="isVisible"
  >
    <workOrder
      @closeOpen="closeOpen"
      style="position: absolute; left: -63px; top: 30px"
    />
  </el-dialog>
</template>

<script setup>
import workOrder from "@/views/workOrder";
import { ref, onMounted } from "vue";
import emitter from "@/utils/eventBus";
import { useRouter } from "vue-router";

const router = useRouter();

const handleOpen = () => {
  emitter.emit("onlineClient");
};
const isVisible = ref(false);
const workOrderSubmite = () => {
  // isVisible.value = true;
  router.push({
    path: "/Customize",
    query: {
      item: "customizeDataList",
    },
  });
};
const closeOpen = () => {
  isVisible.value = false;
};
</script>

<style lang="less" scoped>
.lx {
  width: 1920px;
  background-color: #f7f7f7;
  margin: 0 auto;
  // padding-top: 30px;
  // .home-b {
  //   width: 1440px;
  //   height: 200px;
  //   background-image: url("../../../assets/images/Lx/z-1 (13).png");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  //   margin: 0 auto;
  //   .home-b-text {
  //     display: flex;
  //     padding: 0 0 0 122px;
  //     .home-left {
  //       width: 326px;
  //       padding: 54px 0 61px 0;
  //       p:nth-child(1) {
  //         font-size: 20px;
  //         font-weight: 400;
  //         color: #111111;
  //         margin-bottom: 5px;
  //       }
  //       P:nth-child(2) {
  //         font-size: 18px;
  //         font-weight: 400;
  //         color: #666666;
  //         margin-bottom: 5px;
  //       }
  //       P:nth-child(3) {
  //         font-size: 24px;
  //         font-weight: 400;
  //         color: #ce1200;
  //         .ht {
  //           display: inline-block;
  //           width: 20px;
  //           height: 24px;
  //           background-image: url("../../../assets/images/Lx/z-1-1.png");
  //           background-repeat: no-repeat;
  //           background-position: center;
  //           background-size: cover;
  //           margin-right: 7px;
  //         }
  //       }
  //     }
  //     .home-left-1 {
  //       margin-top: 54px;
  //       width: 369px;
  //       p:nth-child(1) {
  //         font-size: 20px;
  //         font-weight: 400;
  //         color: #111111;
  //         margin-bottom: 5px;
  //       }
  //       P:nth-child(2) {
  //         font-size: 18px;
  //         font-weight: 400;
  //         color: #666666;
  //         margin-bottom: 5px;
  //       }
  //       P:nth-child(3) {
  //         font-size: 24px;
  //         font-weight: 400;
  //         margin-left: 3px;
  //         color: #ce1200;
  //         .ht {
  //           display: inline-block;
  //           /* width: 24px;
  //           height: 24px; */
  //           background-image: url("../../../assets/images/Lx/z-1-4.png");
  //           background-repeat: no-repeat;
  //           background-position: center;
  //           background-size: cover;
  //           margin-right: 7px;
  //         }
  //       }
  //     }
  //     .home-two {
  //       display: flex;
  //       .home-content {
  //         padding: 45px 48px 45px 0;
  //         p:nth-child(1) {
  //           width: 40px;
  //           height: 36px;
  //           background-image: url("../../../assets/images/solve/td.png");
  //           background-repeat: no-repeat;
  //           background-position: center;
  //           background-size: cover;
  //           margin: auto;
  //         }
  //         p:nth-child(2) {
  //           font-size: 18px;
  //           font-family: Microsoft YaHei;
  //           font-weight: 400;
  //           color: #111111;
  //           margin: 14px 0 11px 0;
  //         }
  //         p:nth-child(3) {
  //           width: 120px;
  //           height: 30px;
  //           line-height: 30px;
  //           background: #ce1200;
  //           border-radius: 15px;
  //           color: #ffffff;
  //           margin: 0 auto;
  //           text-align: center;
  //           cursor: pointer;
  //         }
  //       }
  //       .home-right {
  //         padding: 41px 0 39px 0;
  //         p:nth-child(1) {
  //           margin-bottom: 11px;
  //           width: 43px;
  //           height: 43px;
  //           background-image: url("../../../assets/images/solve/xz.png");
  //           background-repeat: no-repeat;
  //           background-position: center;
  //           background-size: cover;
  //           margin: auto;
  //         }
  //         P:nth-child(2) {
  //           font-size: 18px;
  //           font-family: Microsoft YaHei;
  //           font-weight: 400;
  //           color: #111111;
  //           margin: 11px 0 11px 0;
  //         }
  //         p:nth-child(3) {
  //           width: 120px;
  //           height: 30px;
  //           line-height: 30px;
  //           background: #ce1200;
  //           border-radius: 15px;
  //           color: #ffffff;
  //           margin: 0 auto;
  //           text-align: center;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }
  // }
  .lx-content {
    width: 100%;
    height: 340px;
    border-color: #f7f7f7;
    padding-top: 60px;
    .content-fore {
      width: 1440px;
      height: 220px;
      margin: 0 auto;
      display: flex;
      .one-1 {
        width: 348px;
        height: 220px;
        background: #fff;
        border-radius: 20px;
        margin-right: 16px;
        .one-1-1 {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          margin-top: 36px;
          margin-bottom: 27px;
        }
        .one-1-2 {
          width: 280px;
          margin-left: 33px;
          margin-bottom: 18px;
          :nth-child(1) {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #111111;
          }
          :nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ce1200;
          }
        }
        .one-1-3 {
          width: 238px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-left: 56px;
        }
      }
      .one-2 {
        width: 348px;
        height: 220px;
        background: #fff;
        border-radius: 20px;
        margin-right: 16px;
        .one-1-1 {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          margin-top: 36px;
          margin-bottom: 27px;
        }
        .one-1-2 {
          width: 290px;
          margin-left: 33px;
          margin-bottom: 18px;
          :nth-child(1) {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #111111;
          }
          :nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ce1200;
          }
        }
        .one-1-3 {
          width: 267px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-left: 40px;
        }
      }
      .one-3 {
        width: 348px;
        height: 220px;
        background: #fff;
        border-radius: 20px;
        margin-right: 16px;
        cursor: pointer;
        .one-1-1 {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          margin-top: 36px;
          margin-bottom: 27px;
        }
        .one-1-2 {
          width: 94px;
          margin-bottom: 18px;
          margin-left: 134px;
          :nth-child(1) {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #111111;
          }
          :nth-child(2) {
            img {
              width: 6px;
              height: 12px;
              position: relative;
              top: -3px;
              left: 8px;
            }
          }
        }
        .one-1-3 {
          width: 281px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-left: 33px;
        }
        .hoverShadow ();
      }
      .one-4 {
        width: 348px;
        height: 220px;
        background: #fff;
        border-radius: 20px;
        margin-right: 16px;
        cursor: pointer;
        .one-1-1 {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          margin-top: 36px;
          margin-bottom: 27px;
        }
        .one-1-2 {
          width: 94px;
          margin-bottom: 18px;
          margin-left: 134px;
          :nth-child(1) {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #111111;
          }
          :nth-child(2) {
            img {
              width: 6px;
              height: 12px;
              position: relative;
              top: -3px;
              left: 8px;
            }
          }
        }
        .one-1-3 {
          width: 281px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-left: 33px;
        }
        .hoverShadow ();
      }
    }
  }
}
</style>
