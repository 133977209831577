<template>
  <div class="container-banner">
    <div class="banner">
      <!-- <img src="../../../assets/banner/hard-brann.png" alt="" /> -->
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.container-banner {
  width: 1920px;
  height: 600px;
  background-image: url("../../../assets/images/Lx/z-1 (15).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
}
</style>
